@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Vazir";
  font-weight: 400;
  src: url("../public/fonts/Vazir.woff2");
}
@font-face {
  font-family: "Vazir";
  font-weight: 300;
  src: url("../public/fonts/Vazir-Light.woff2");
}
@font-face {
  font-family: "Vazir";
  font-weight: 700;
  src: url("../public/fonts/Vazir-Bold.woff2");
}

:root {
  --clr-accent-400: hsl(234, 74%, 71%);
  --clr-accent-500: hsl(216, 44%, 57%);
  --clr-accent-600: hsl(216, 42%, 52%);

  --clr-neutral-100: hsl(0, 0%, 97%);
  --clr-neutral-200: hsl(0, 0%, 95%);
  --clr-neutral-300: hsl(260, 6%, 90%);
  --clr-neutral-700: hsl(0, 0%, 40%);
  --clr-neutral-800: hsl(0, 0%, 28%);
  --clr-neutral-900: hsl(0, 0%, 10%);
}

* {
  scroll-padding: 2rem;
  object-fit: cover;
}

body {
  font-family: Vazir;
  color: var(--clr-neutral-900);
}

.header-1 {
  @apply font-bold text-3xl;
}
.header-2 {
  @apply font-bold text-2xl;
}
.header-3 {
  @apply font-bold text-xl;
}

.subheader {
  @apply text-accent-500 uppercase text-base;
}

.base {
  @apply text-neutral-x-800;
}
.sub {
  @apply text-neutral-x-700;
}

.container {
  max-width: 1000px;
  padding-inline: 1rem;
  margin-inline: auto;
}

.section {
  padding-block: 2rem;
  background-color: white;
}
.section:nth-child(even) {
  background-color: var(--clr-neutral-100);
}
.section.footer {
  @apply text-sm text-white bg-gradient-to-tr from-accent-500 to-accent-400;
}

.layout {
  @apply grid grid-cols-1 md:grid-cols-2 gap-8 justify-items-center;
}

.hor-1 {
  @apply flex gap-1;
}
.hor-2 {
  @apply flex gap-2;
}
.hor-4 {
  @apply flex gap-4 max-md:gap-2;
}

.ver-1 {
  @apply grid gap-1;
}
.ver-2 {
  @apply grid gap-2;
}
.ver-4 {
  @apply grid gap-4 max-md:gap-2;
}
